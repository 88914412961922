import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { FormBuilder } from '@angular/forms';
import { ExportService } from '../../../services/export.service';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { BookingRecord } from '@pulpo/pulpo-models';
import {
  BookingReceptiveService,
  BookingRecordService,
  StorageService,
} from '@pulpo/pulpo-api';
import { BookingRecordFilterComponent } from '../booking-record-filter/booking-record-filter.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BookingStatusPipe } from '../../../pipes';
import { PulpoSnackbarComponent } from '../../pulpo-snackbar/pulpo-snackbar.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { HideForEnvsDirective } from '../../../directives';
import { SimplePulpoDialogComponent } from '../../simple-pulpo-dialog/simple-pulpo-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'booking-record-list',
  templateUrl: './booking-record-list.component.html',
  styleUrls: ['./booking-record-list.component.scss'],
  standalone: true,
  imports: [
    BookingRecordFilterComponent,
    MatTableModule,
    MatCheckboxModule,
    DatePipe,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    BookingStatusPipe,
    MatSnackBarModule,
    CommonModule,
    HideForEnvsDirective,
    SimplePulpoDialogComponent,
  ],
})
export class BookingRecordListComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'id',
    'status',
    'creationDate',
    'destination',
    'productReference',
    'product',
    'startCity',
    'departureDate',
    'nbNights',
    'categoryName',
    'firstPax',
    'total',
    'supplier',
    'seller',
    'distribution',
    'balenoId',
    'options',
    'consult',
  ];

  //List
  dataSource!: MatTableDataSource<BookingRecord>;
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;
  @ViewChild(MatPaginator, { static: false })
  paginator!: MatPaginator;
  page!: number;
  filter: any = {
    draft: false,
  };
  loading = true;
  nbBookings!: number;

  //Export
  canExport = false;
  allChecked = false;
  selectedBookings: any = {};

  @Input() showOpened = true;
  @Input() producerId?: number;
  @Input() itemsPerPage = 20;

  constructor(
    protected bookingRecordService: BookingRecordService,
    protected storageService: StorageService,
    protected formBuilder: FormBuilder,
    private readonly exportService: ExportService,
    private readonly bookingReceptiveService: BookingReceptiveService,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.page = 0;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.refreshBookings();
  }

  private refreshBookings() {
    this.loading = true;
    const sort = this.sort?.active || 'businessId';
    const order = this.sort?.direction || 'desc';
    let filter = {
      page: this.page,
      size: this.itemsPerPage,
      sort: sort,
      direction: order,
      ...this.filter,
    };
    if (!this.showOpened) {
      filter = { ...filter, opened: false };
    }
    if (this.producerId) {
      filter = {
        ...filter,
        producerCompanyId: this.producerId,
        supplierCompanyId: this.producerId,
      };
    }
    this.bookingRecordService.queryPage(filter).subscribe(
      (res: any) => {
        this.dataSource = new MatTableDataSource(res.body?.content);
        this.nbBookings = res.body?.totalElements;
        this.allChecked =
          this.dataSource.data.length > 0 &&
          this.dataSource.data.every(
            (booking) => this.selectedBookings[booking.id || '']
          );
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  toggleCheck(booking: any) {
    if (this.selectedBookings[booking.businessId]) {
      delete this.selectedBookings[booking.businessId];
    } else {
      this.selectedBookings[booking.businessId] = booking;
    }
    this.allChecked = this.dataSource.data.every(
      (booking) => this.selectedBookings[booking.businessId || '']
    );
    this.canExport = this.selectedBookings !== undefined;
  }

  checkAll() {
    this.dataSource.data.forEach((booking) => {
      if (this.allChecked) {
        delete this.selectedBookings[booking.businessId || ''];
      } else {
        this.selectedBookings[booking.businessId || ''] = booking;
      }
    });

    this.allChecked = !this.allChecked;
    this.canExport = this.selectedBookings !== undefined;
  }

  applyFilter(filter: any) {
    this.page = 0;
    this.filter = {};
    for (const key in filter) {
      if (filter[key] !== null && filter[key] !== undefined) {
        if (filter[key] instanceof Date) {
          this.filter[key] = dayjs(filter[key]).toISOString();
        } else if (dayjs.isDayjs(filter[key])) {
          this.filter[key] = dayjs(filter[key]).toISOString();
        } else {
          this.filter[key] = filter[key];
        }
      }
    }
    this.refreshBookings();
  }

  changePage() {
    this.page = this.paginator.pageIndex;
    this.refreshBookings();
  }

  sortChanged() {
    this.page = 0;
    this.paginator.pageIndex = 0;
    this.refreshBookings();
  }

  exportResa() {
    const blob = this.exportService.exportBookingRecordsToExcel(
      this.storageService.getCompany().name || '',
      Object.keys(this.selectedBookings).map(
        (key) => this.selectedBookings[key]
      )
    );
    saveAs(blob, 'bookings_' + dayjs().format('DD-MM-YYYY') + '.xlsx');
  }

  exportBaleno(id: string, businessId: string) {
    this.bookingReceptiveService.exportBaleno(id).subscribe((res) => {
      const hasError = res.body?.error && res.body?.error !== '';
      if (hasError) {
        this.dialog.open(SimplePulpoDialogComponent, {
          data: {
            title: `Une erreur est survenue dans l'export du dossier n° ${businessId} vers baleno`,
            titleIcon: 'error',
            content: res.body?.error,
            cancelButton: false,
            okButton: true,
          },
          panelClass: ['dialog'],
        });
      } else {
        this.snackBar.openFromComponent(PulpoSnackbarComponent, {
          data: {
            title: `Export du dossier n° ${businessId} vers Baleno réalisé avec succès`,
            message: `Le dossier baleno porte la référence ${res.body?.recordRef}`,
          },
          duration: 2000,
          panelClass: ['snackbar'],
        });
      }
    });
  }
}
