import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  ConditionEnum,
  CurrencyTypeEnum,
  EventTypeEnum,
  PaymentTerm,
  PaymentTypeEnum,
} from '@pulpo/pulpo-models';
import { HideForEnvsDirective } from '../../../directives';

const paymentConditionsValidator: any = (formArray: FormArray) => {
  const rules = formArray.value;
  let errors = {};
  for (let i = 0; i < rules.length - 1; i++) {
    if (rules[i].period.endDay !== rules[i + 1].period.startDay - 1) {
      errors = { ...errors, rulesOverlap: true };
      break;
    }
  }
  if (rules.length > 0 && rules[0].period.startDay !== 1) {
    errors = { ...errors, rulesStart: true };
  }
  if (rules.length > 0 && rules[rules.length - 1].period.endDay !== 31) {
    errors = { ...errors, rulesEnd: true };
  }
  return errors;
};

@Component({
  selector: 'payment-term-edit-dialog',
  templateUrl: './payment-term-edit-dialog.component.html',
  styleUrls: ['./payment-term-edit-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    HideForEnvsDirective,
  ],
})
export class PaymentTermEditDialogComponent {
  editForm: FormGroup;
  PaymentTypeEnum = PaymentTypeEnum;
  EventTypeEnum = EventTypeEnum;
  CurrencyTypeEnum = CurrencyTypeEnum;
  ConditionEnum = ConditionEnum;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      PaymentTerm: PaymentTerm;
    },
    public dialogRef: MatDialogRef<PaymentTermEditDialogComponent>,
    private fb: FormBuilder
  ) {
    this.editForm = this.fb.group({
      id: [null],
      paymentId: [null],
      paymentType: [null, [Validators.required]],
      ruleBasedPayment: this.fb.group({
        rules: this.fb.array([], { validators: paymentConditionsValidator }),
      }),
      fixedDatePayment: this.fb.group({
        downpayment: this.fb.group({
          amount: [null],
          type: [null],
        }),
        hasDownpayment: [null],
        installments: this.fb.array([]),
      }),
    });
    this.editForm.patchValue(data?.PaymentTerm);
    if (
      !data.PaymentTerm?.ruleBasedPayment ||
      data.PaymentTerm?.ruleBasedPayment?.rules.length === 0
    ) {
      this.addRule();
    } else {
      for (const rule of data?.PaymentTerm?.ruleBasedPayment?.rules || []) {
        this.addRule(rule);
      }
    }

    if (
      !data.PaymentTerm?.fixedDatePayment ||
      data.PaymentTerm?.fixedDatePayment?.installments.length === 0
    ) {
      this.addInstallment();
    } else {
      for (const installment of (data?.PaymentTerm?.fixedDatePayment || [])
        .installments) {
        this.addInstallment(installment);
      }
    }

    if (data.PaymentTerm?.paymentType === PaymentTypeEnum.RULE_BASED) {
      this.editForm.get('fixedDatePayment')?.disable();
    } else {
      this.editForm.get('ruleBasedPayment')?.disable();
    }
  }

  validate() {
    const paymentTerm: PaymentTerm = this.editForm.value;
    if (paymentTerm.paymentType === PaymentTypeEnum.FIXED_DATE) {
      paymentTerm.fixedDatePayment.hasDownpayment =
        paymentTerm.fixedDatePayment.downpayment?.amount > 0;
    }
    this.dialogRef.close(this.editForm.value);
  }

  onPaymentTypeChange() {
    if (
      this.editForm.get('paymentType')?.value === PaymentTypeEnum.RULE_BASED
    ) {
      this.editForm.get('fixedDatePayment')?.reset();
      this.editForm.get('fixedDatePayment')?.disable();
      this.editForm.get('ruleBasedPayment')?.enable();
    } else {
      this.editForm.get('ruleBasedPayment')?.reset();
      this.editForm.get('ruleBasedPayment')?.disable();
      this.editForm.get('fixedDatePayment')?.enable();
    }
  }

  get rules(): FormArray {
    return (this.editForm.get('ruleBasedPayment.rules') as FormArray) || null;
  }

  addRule(rule: any = null) {
    const gp = this.fb.group({
      period: this.fb.group({
        event: [null, [Validators.required]],
        startDay: [null, [Validators.required]],
        endDay: [null, [Validators.required]],
      }),
      paymentDue: this.fb.group({
        dayOfMonth: [null, [Validators.required]],
        monthOffset: [null, [Validators.required]],
      }),
    });

    gp.patchValue(rule);
    this.rules.push(gp);
  }

  removeRule(index: number) {
    this.rules.removeAt(index);
  }

  get installments(): FormArray {
    return (
      (this.editForm.get('fixedDatePayment.installments') as FormArray) || null
    );
  }

  addInstallment(installment: any = null) {
    const gp = this.fb.group({
      amountPercentage: [null, []],
      daysFromEvent: [null, [Validators.required]],
      condition: [null, [Validators.required]],
      event: [null, [Validators.required]],
    });

    gp.patchValue(installment);
    this.installments.push(gp);
  }

  removeInstallment(index: number) {
    this.installments.removeAt(index);
  }
}
